// Entry point for the build script in your package.json
import "@hotwired/turbo-rails"
import "./controllers"
import * as bootstrap from "bootstrap"
import './jquery'
import './feather-icons'

window.users = {}
window.users.validatePasswordFields = function () {
    const password = document.getElementById('user_password');
    const password_confirmation = document.getElementById('user_password_confirmation');

    if (password.value != password_confirmation.value) {
        password.setCustomValidity('Password and confirmation must match.');
    } else if (password.value.length < 8) {
        password.setCustomValidity('Password is too short (minimum is eight characters).');
    } else {
        password.setCustomValidity('');
    }
}
