import feather from 'feather-icons'
window.feather = feather

$(document).on('turbo:render', function(e) {
    feather.replace();
});

window.addEventListener('load', () => {
    feather.replace();
});
