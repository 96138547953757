import jquery from 'jquery'
import feather from "feather-icons";
window.jQuery = jquery
window.$ = jquery

// $(function(){
const hideAlert = function() {
    let alert = $('#alert');
    if (alert.length) {
        alert.hide(400);
    }
};

const setDeleteAlert = function() {
    $('td.actions a[data-turbo-method="delete"]').click(function(e){
        return confirm($(this).data('confirm'));
    });
}

$(document).on('turbo:render', function(e) {
    setTimeout(hideAlert, 3000);
    setDeleteAlert();
});

window.addEventListener('load', () => {
    setTimeout(hideAlert, 3000);
    setDeleteAlert();
});
